<template>
  <div class="AppManage">
    <!--主体内容-->
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="70px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="
              $router.push({
                path: '/updateAndroid',
                query: {
                  applicationId: $route.query.applicationId,
                  isEdit: 'add',
                },
              })
            "
            >添加新版本</el-button
          >
           <el-button type="text" style="position: absolute;right:30px" @click="$router.replace('/appmanager')"
        >&lt;&lt;返回</el-button
      >
      <!--搜索条件区域-->
      <TitleModule title="爱泊车Android版本列表" style="padding-top:20px"></TitleModule>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            show-overflow-tooltip
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column align="center" label="操作" width="90px">
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0">操作<i class="el-icon-arrow-down" /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">查看 </el-dropdown-item>
                  <el-dropdown-item
                    command="2"
                    v-show="scope.row.upgradeStatus !== 2"
                    >编辑
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="3"
                    v-show="scope.row.upgradeStatus.toString() === '0'"
                    >发布
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="4"
                    v-show="scope.row.upgradeStatus === 1"
                    >下架
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper" v-show="total > 0">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleModule from "@/components/titleModule/titleModule";
export default {
  name: "updateIOSRecord",
  components: {
    TitleModule,
  },
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      roleList: [],
      loading: false,
      detailFlag: false,
      rsTypeList: {},
      tableData: [],
      formInline: {
        title: "",
        jumpType: "",
        state: "",
        applicationName: "",
      },
      tableCols: [
        {
          label: "版本名",
          prop: "versionName",
          width: "100",
        },
        {
          label: "VersionCode",
          prop: "versionCode",
          width: "120",
        },
        {
          label: "升级方式",
          prop: "updateType",
          width: "100",
          formatter: (row) => {
            if (row.updateType === 1) {
              return "强制更新";
            } else if (row.updateType === 2) {
              return "推荐更新";
            } else if (row.updateType === 3) {
              return "手动更新";
            } else {
              return "";
            }
          },
        },
        {
          label: "更新说明",
          prop: "updateDesc",
        },
        {
          label: "升级时间",
          prop: "createTime",
          width: "160",
        },
        {
          label: "升级状态",
          prop: "upgradeStatus",
          width: "160",
          formatter: (row) => {
            if (row.upgradeStatus === 1) {
              return "已发布";
            } else if (row.upgradeStatus === 2) {
              return "已下架";
            } else {
              return "未发布";
            }
          },
        },
        {
          label: "创建人",
          prop: "promulgatorName",
          width: "100",
        },
      ],
    };
  },
  methods: {
    // 操作细则
    handleCommand(command, row) {
      if (command === "1") {
        this.$router.push({
          path: "/updateAndroid",
          query: { applicationId: row.applicationId, recordId: row.recordId },
        });
      } else if (command === "2") {
        this.$router.push({
          path: "/updateAndroid",
          query: {
            isEdit: true,
            applicationId: row.applicationId,
            recordId: row.recordId,
          },
        });
      } else if (command === "3") {
        this.$confirm("确定要发布吗？").then((res) => {
          if (res === "confirm") {
            this.online(row);
          }
        });
      } else if (command === "4") {
        this.$confirm("确定要下架吗？").then((res) => {
          if (res === "confirm") {
            this.offline(row);
          }
        });
      }
    },
    offline(data) {
      this.$axios
        .post("/acb/2.0/application/record/offline", {
          data: {
            applicationId: data.applicationId,
            recordId: data.recordId,
          },
        })
        .then(() => {
          this.$message.success("操作成功！");
          this.searchData();
        });
    },
    online(data) {
      this.$axios
        .post("/acb/2.0/application/record/online", {
          data: {
            applicationId: data.applicationId,
            recordId: data.recordId,
          },
        })
        .then(() => {
          this.$message.success("操作成功！");
          this.searchData();
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.tableData = [];
      this.$axios
        .get("/acb/2.0/application/record/queryList", {
          data: {
            applicationId: this.$route.query.applicationId,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  created() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to { /* .fade-leave-active in below version 2.1.8 */
  opacity: 0;
}
</style>
