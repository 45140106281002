var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "AppManage" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.searchData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "status-icon": "",
                "label-position": "right",
                "label-width": "70px",
                model: _vm.formInline,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/updateAndroid",
                        query: {
                          applicationId: _vm.$route.query.applicationId,
                          isEdit: "add",
                        },
                      })
                    },
                  },
                },
                [_vm._v("添加新版本")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { position: "absolute", right: "30px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.replace("/appmanager")
                    },
                  },
                },
                [_vm._v("<<返回")]
              ),
              _c("TitleModule", {
                staticStyle: { "padding-top": "20px" },
                attrs: { title: "爱泊车Android版本列表" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableWrapper" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _vm._l(_vm.tableCols, function (item) {
                return _c("el-table-column", {
                  key: item.prop,
                  attrs: {
                    align: "center",
                    "show-overflow-tooltip": "",
                    label: item.label,
                    prop: item.prop,
                    width: item.width,
                    formatter: item.formatter,
                  },
                })
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "90px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-dropdown",
                          {
                            staticStyle: {
                              color: "#20a0ff",
                              cursor: "pointer",
                            },
                            on: {
                              command: function ($event) {
                                return _vm.handleCommand($event, scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { padding: "0" },
                                attrs: { type: "text", size: "small" },
                              },
                              [
                                _vm._v("操作"),
                                _c("i", { staticClass: "el-icon-arrow-down" }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "1" } },
                                  [_vm._v("查看 ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.upgradeStatus !== 2,
                                        expression:
                                          "scope.row.upgradeStatus !== 2",
                                      },
                                    ],
                                    attrs: { command: "2" },
                                  },
                                  [_vm._v("编辑 ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.row.upgradeStatus.toString() ===
                                          "0",
                                        expression:
                                          "scope.row.upgradeStatus.toString() === '0'",
                                      },
                                    ],
                                    attrs: { command: "3" },
                                  },
                                  [_vm._v("发布 ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.upgradeStatus === 1,
                                        expression:
                                          "scope.row.upgradeStatus === 1",
                                      },
                                    ],
                                    attrs: { command: "4" },
                                  },
                                  [_vm._v("下架 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          staticClass: "pagerWrapper",
        },
        [
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pageNum,
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }